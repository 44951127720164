import React from 'react'
import headerLogoImage from './../../assets/headerinvoice.png';
import footerImage from './../../assets/signature.jpg';
import mainFont from './../../fonts/OpenSansFont.ttf';
import lightFont from './../../fonts/OpenSansLight.ttf';
import boldFont from './../../fonts/OpenSansBold.ttf';

import { Document, Page, pdf, Image, Text, View, StyleSheet, Font } from '@react-pdf/renderer';

Font.register({
    family: 'OpenSans',
    fonts: [
      { src: mainFont, fontStyle: 'normal', fontWeight: 'normal' },
      { src: lightFont, fontStyle: 'normal', fontWeight: 'light' },
      { src: boldFont, fontStyle: 'normal', fontWeight: 'bold' },
    ],
});

// Define the styles
const styles = StyleSheet.create({
    page: {
        fontFamily: 'OpenSans',
        fontSize: 12,
        padding: 20,
    },
    header: {
        marginBottom: 20,
    },
    txtHeader: {
        marginTop:15,
        fontFamily: 'OpenSans',
        fontSize: 10, // Adjust the font size here
        fontWeight: 'light',
    },
    subtotal: {
        alignItems: 'flex-end',
        padding:30
    },
    section: {
        margin: 20,
        padding: 10,
        flexGrow: 1,
    },
    twoColumnLayout: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between', // Makes space between the columns
        marginBottom: 10,
    },
    leftColumn: {
        flex: 1, // Takes up half the width
    },
    rightColumn: {
        flex: 1, // Takes up half the width
        textAlign: 'right', // Aligns the text to the right
    },  
    hr: {
        marginVertical: 12,
        height: 1,
        backgroundColor: '#000',
    },
    footer: {
        marginTop: 20,
        textAlign: 'center',
    },
    body: {
        flexGrow: 1,
    },
    image: {
        width: '100%',
    },
    text: {
        marginTop: 16,
        fontSize: 11,
        textAlign: 'justify',
    },
    textA: {
        marginTop: 5,
        fontSize: 10,
        textAlign: 'justify',
        fontWeight: 'light',
    },
    textSt1: {
        fontSize: 11,
        textAlign: 'justify',
    },
    textB : {
        fontWeight: '900',
    },
    tableRow: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderBottomColor: '#ccc',
        paddingVertical: 8,
      },
      tableCell: {
        flex: 1,
        textAlign: 'center',
      },
      totalRow: {
        flexDirection: 'row',
        marginTop: 10,
      },
      totalText: {
        fontWeight: 'bold',
        fontSize: 16,
      },
});

function PrinterList({ dataScriptObject}) {
    const totalPayment = dataScriptObject.invoice.reduce((total, voice) => 
        parseFloat(total) + parseFloat(voice.payment.charge), 0).toFixed(2);
 
  return (
    <Document>
        <Page size="A4" style={styles.page}>
            <View style={styles.header}>
                <Image src={headerLogoImage} style={styles.image} />
            </View>
            <View style={styles.section}>  
                                         
                    <View style={styles.twoColumnLayout}>
                        {/* Left Section */}
                        <View style={styles.leftColumn}>
                            <Text style={styles.textA}>Title: {dataScriptObject.pharmacyTitle}</Text>
                            <Text style={styles.textA}>Province: {dataScriptObject.pharmacyProvince}</Text>
                            <Text style={styles.textA}>Email: {dataScriptObject.pharmacyEmail}</Text>
                            <Text style={styles.textA}>Date: { new Date(dataScriptObject.currentDate).toLocaleDateString(undefined, {
                                                                        year: 'numeric',
                                                                        month: 'long',
                                                                        day: 'numeric',
                                                                    })}</Text>
                        </View>

                        {/* Right Section */}
                        <View style={styles.rightColumn}>
                            <Text style={styles.textA}>Account no: {dataScriptObject.bankDetails?.accountNumber ?? ''}</Text>
                            <Text style={styles.textA}>Bank: {dataScriptObject.bankDetails?.bankName ?? ''} </Text>
                            <Text style={styles.textA}>Branch Code: {dataScriptObject.bankDetails?.branchCode ?? ''}</Text>
                            <Text style={styles.textA}>Branch Name: {dataScriptObject.bankDetails?.branchName ?? ''}</Text>
                        </View>
                    </View> 

                    <View style={styles.body}>

                    <View style={styles.tableRow}>
                        <Text style={[styles.tableCell, styles.headerCell]}>No.</Text>
                        <Text style={[styles.tableCell, styles.headerCell]}>Script Code</Text>
                        <Text style={[styles.tableCell, styles.headerCell]}>Full Name</Text>
                        <Text style={[styles.tableCell, styles.headerCell]}>Expiry Date</Text>
                        <Text style={[styles.tableCell, styles.headerCell]}>Payment</Text>
                    </View>

                    {/* Table Rows */}
                    {dataScriptObject.invoice.map((voice, index) => (
                        <View key={index} style={styles.tableRow}>
                            <Text style={styles.tableCell}>{index + 1}</Text>
                            <Text style={styles.tableCell}>{voice.scriptcode}</Text>
                            <Text style={styles.tableCell}>{voice.fullname}</Text>
                            <Text style={styles.tableCell}>{ new Date(voice.expiryDate).toLocaleDateString(undefined, {
                                                                        year: 'numeric',
                                                                        month: 'long',
                                                                        day: 'numeric',
                                                                    })}</Text>
                            <Text style={styles.tableCell}>{voice.payment.charge}</Text>
                        </View>
                        ))}
                    </View>
                    <View style={styles.totalRow}>
                        <Text style={[styles.tableCell, styles.totalText]}>Total</Text>
                        <Text style={[styles.tableCell, styles.totalText]}>R {totalPayment}</Text>
                    </View>
                </View>
        </Page>
    </Document>
  )
}

export default PrinterList