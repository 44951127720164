import React from 'react'
import headerLogoImage from './../../assets/headerinvoice.png';
import mainFont from './../../fonts/OpenSansFont.ttf';
import lightFont from './../../fonts/OpenSansLight.ttf';
import boldFont from './../../fonts/OpenSansBold.ttf';

import { Document, Page, pdf, Image, Text, View, StyleSheet, Font } from '@react-pdf/renderer';

Font.register({
    family: 'OpenSans',
    fonts: [
      { src: mainFont, fontStyle: 'normal', fontWeight: 'normal' },
      { src: lightFont, fontStyle: 'normal', fontWeight: 'light' },
      { src: boldFont, fontStyle: 'normal', fontWeight: 'bold' },
    ],
});

// Define the styles
const styles = StyleSheet.create({
    page: {
        fontFamily: 'OpenSans',
        fontSize: 12,
        padding: 20,
    },
    header: {
        marginBottom: 20,
    },
    txtHeader: {
        marginTop:15,
        fontFamily: 'OpenSans',
        fontSize: 10, // Adjust the font size here
        fontWeight: 'light',
    },
    subtotal: {
        alignItems: 'flex-end',
        padding:30
    },
    section: {
        margin: 20,
        padding: 10,
        flexGrow: 1,
    },
    footer: {
        marginTop: 20,
        textAlign: 'center',
    },
    body: {
        flexGrow: 1,
    },
    image: {
        width: '100%',
    },
    text: {
        marginTop: 16,
        fontSize: 11,
        textAlign: 'justify',
    },
    textA: {
        marginTop: 5,
        fontSize: 10,
        textAlign: 'justify',
        fontWeight: 'light',
    },
    textSt1: {
        fontSize: 11,
        textAlign: 'justify',
    },
    textB : {
        fontWeight: '900',
        marginTop: 10,
    }
});

function PrinterMedical({dataObject}) {
  return (
    <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.header}>
                    <Image src={headerLogoImage} style={styles.image} />
                </View>
                <View style={styles.txtHeader}>
                    <Text style={styles.text}>Medical Aid INVOICE</Text>
                    <Text style={styles.textA}>Date: { new Date(dataObject.currentDate).toLocaleDateString(undefined, {
                                                                    year: 'numeric',
                                                                    month: 'long',
                                                                    day: 'numeric',
                                                                })}</Text>
                    
                </View>
                <View style={styles.body}>
                        <Text style={styles.textB}>PATIENT DETAILS</Text>
                        <Text style={styles.textSt1}>Full Name: {dataObject?.fullname ?? ''}</Text>
                        <Text style={styles.textSt1}>ID Number: {dataObject?.idNumber ?? ''} </Text>
                        <Text style={styles.textSt1}>Phone number: {dataObject?.phone ?? ''}</Text>

                        <Text style={styles.textB}>SCRIPT DETAILS</Text>
                        <Text style={styles.textSt1}>Script Code: {dataObject.scriptcode} </Text>
                        <Text style={styles.textSt1}>
                            Valid Until: {new Date(dataObject.expiryDate).toLocaleDateString(undefined, {
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric',
                            })}
                        </Text>
                        <Text style={styles.textSt1}>
                           Version : {dataObject.version} / {dataObject.versionCode}            
                        </Text>

                        <Text style={styles.textB}>PHARMACY DETAILS</Text>
                        <Text style={styles.textSt1}>
                            {dataObject.title}
                        </Text>
                        <Text style={styles.textSt1}>
                            {dataObject.pharmacyTitle}
                        </Text>
                        <Text style={styles.textSt1}>
                            {dataObject.pharmacyProvince}
                        </Text>
                        <Text style={styles.textB}>MEDICAL AID DETAILS</Text>
                        <Text style={styles.textSt1}>Medical Aid: {dataObject?.medical.medicalAidName ?? ''}</Text>
                        <Text style={styles.textSt1}>Medical Aid Number: {dataObject?.medical.medicalAidNumber ?? ''} </Text>
                </View>
                            
            </Page>
        </Document>
  )
}

export default PrinterMedical